import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

class TabsTwo extends Component {
  render() {
    let tab1 = "Main skills",
      tab2 = "Awards",
      tab3 = "Experience",
      tab4 = "Education",
      tab5 = "Certifications";
    const { tabStyle } = this.props;
    return (
      <div>
        {/* Start Tabs Area */}
        <div className="tabs-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Tabs>
                  <TabList className={`${tabStyle}`}>
                    <Tab>{tab1}</Tab>
                    <Tab>{tab2}</Tab>
                    <Tab>{tab3}</Tab>
                    <Tab>{tab4}</Tab>
                    <Tab>{tab5}</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                        <div className="font-weight-bold">Data Science</div>
                          Python, SQL, & Django development
                        </li>
                        <li>
                            <div className="font-weight-bold">Web Development</div>
                            React & Angular site development

                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <div className="font-weight-bold">Lettered in Academics</div>
                          Summit Tech Academy
                        </li>
                        <li>
                        <div className="font-weight-bold">Lettered in Academics</div>
                          Lees Summit West High School
                        </li>
                        <li>
                        <div className="font-weight-bold">Honor Roll:</div>
                          3 Years
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                        <div className="font-weight-bold">W2T - Software Developer Intern</div>
                          Apr 2023 - Present
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                        <div className="font-weight-bold">Lees Summit West High School</div>
                          Graduating May 2025
                        </li>
                        <li>  
                          <div className="font-weight-bold">Summit Tech Academy</div>
                          Dual credit Python course: UCM 1030 & 2030
                        </li>
                        <li>
                          <div className="font-weight-bold">Metropolitan Community College (MCC)</div>
                            2024-2026
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <a href="https://www.credly.com/badges/4cf9e7d9-10fa-4c3c-8054-2039e70d00f7/linked_in_profile">
                            <div className="font-weight-bold">CompTIA Data+ ce</div>
                          </a>
                          Data Analytics
                        </li>
                        <li>
                          <div className="font-weight-bold">TestOut PC Pro</div>
                          Software, hardware, & networking
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* End Tabs Area */}
      </div>
    );
  }
}

export default TabsTwo;
