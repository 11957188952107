import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <link rel="shortcut icon" href="../../assets/images/eli_icon.png" />
                    <title>{this.props.pageTitle} || Eli Stewart </title>
                    <meta name="description" content="Resume site for Eli Stewart." />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
