// React Required
import React, { Component } from "react";
// import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client";

// Create Import File
import "./index.scss";

import PageScrollTop from "./component/PageScrollTop";

// Home layout
import PersonalPortfolio from "./home/PersonalPortfolio";
import PortfolioDetailsW2T from "./elements/PortfolioDetailsW2T";
import PortfolioDetailsReact from "./elements/PortfolioDetailsReact";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <PageScrollTop>
          <Switch>
            <Route 
            exact 
            path={`${process.env.PUBLIC_URL}/`} 
            component={PersonalPortfolio} 
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/portfolio-details-w2t/`}
              component={PortfolioDetailsW2T}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/portfolio-details-react/`}
              component={PortfolioDetailsReact}
            />
          </Switch>
        </PageScrollTop>
      </BrowserRouter>
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
serviceWorker.register();
