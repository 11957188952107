import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import HeaderTwo from "../component/header/HeaderTwo";
import FooterTwo from "../component/footer/FooterTwo";
import axios from "axios";


const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
];





class PortfolioDetailsReact extends Component {

  render() {

    const SlideList = [
      {
          textPosition: 'text-center',
          category: 'Portfolio Website',
          title: "",
          description: '',
          buttonText: 'Home',
          buttonLink: '/'
      }
    ]
    return (

      <React.Fragment>
        <PageHelmet pageTitle="Details" />

        <HeaderTwo logo="symbol-dark" color="color-black"/>
      
        

      <div className="slider-wrapper">
        {/* Start Single Slide */}
        
        {SlideList.map((value , index) => (
            <div className="background_header slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image" key={index}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className={`inner ${value.textPosition}`}>
                                {value.category ? <span style={{fontSize: 80}}>{value.category}</span> : ''}
                                {value.title ? <h1 className="title"></h1> : ''}
                                {value.description ? <p className="description">{value.description}</p> : ''}
                                {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))}
        {/* End Single Slide */}
        </div>
        {/* Start Portfolio Details */}
        <div className="rn-portfolio-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="portfolio-details">
                  <div className="inner">
                    <h1>Portfolio Website</h1>
                    <h2></h2>
 
                    <p className="subtitle">
                        On my own time, I worked with ReactJS to create a website which would serve as a record for my projects. The site you’re on is the product I came up with! I created a Python backend which utilizes the Flask framework in order to work collaboratively with my React front end. I used a SQL database, PostgreSQL, in order to store Json objects. I finalized my project by using Microsoft Azure-Container App Instances for deployment.
                    </p>
                    <div className="portfolio-view-list d-flex flex-wrap">
                      <div className="port-view">
                        <span>Tools Used: </span>
                        <h4>ReactJS, Flask, PostgreSQL, Microsoft Azure, Vercel</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <FooterTwo />
      </React.Fragment>
    );
  }
}
export default PortfolioDetailsReact;
