import axios from 'axios';
import React , {useState} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const Result = () => {
    return (
        <p className="success-message">Your Message has been successfully sent. I will contact you soon.</p>
    )
}

const Fail = () => {
    return (
        <p className="failure-message">Please complete the recaptcha.</p>
    )
}

const SomethingWentWrong = () => {
    return (
        <p className="failure-message">Something went wrong. Please try again.</p>
    )
}

function ContactForm({props}) {
    const [result, showresult] = useState(false);
    const [isRecaptchaValid, setIsRecaptchaValid] = useState(false);
    const recaptchaRef = React.createRef();

    
    const handleRecaptcha = (value) => {
        setIsRecaptchaValid(!!value);
    };
    

    const sendEmail = (e) => {
        e.preventDefault();

        axios({
            method: "POST",
            url: process.env.REACT_APP_API_URL + "/send_email",
            data: {
                subject: e.target.subject.value,
                email: e.target.email.value,
                body: e.target.message.value,
                fullName: e.target.fullName.value,

            },
            
        })
        .then((response) => {
            console.log(response)
            showresult(true);
        }).catch((error) => {
            if (error.response) {
            console.log(error.response)
            console.log(error.response.status)
            console.log(error.response.headers)
            }
        })
        e.target.reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
        <form action="" onSubmit={sendEmail}>
            <div className="rn-form-group">
                <input 
                type="text"
                name="fullName"
                placeholder="Your Name"
                required
                />
            </div>

            <div className="rn-form-group">
                <input 
                type="email"
                name="email"
                placeholder="Your Email"
                required
                />
            </div>

            <div className="rn-form-group">
                <input 
                type="text"
                name="subject"
                placeholder="Subject"
                required
                />
            </div>
            
            <div className="rn-form-group">
                <textarea 
                name="message"
                placeholder="Your Message"
                required
                >
                </textarea>
            </div>

            <div className="rn-form-group">
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA}
                    onChange={handleRecaptcha}
                    ref={recaptchaRef}
                    />
            </div>



            <div className="rn-form-group">
                <button style={{marginTop: 20}} className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">
                    Submit
                </button>
            </div> 

            <div className="rn-form-group">
                {result && isRecaptchaValid ? <Result /> : null}
                {result && !isRecaptchaValid ? <Fail /> : null}

            </div> 
        </form>
    )
}
export default ContactForm;
